import React from 'react';
import myvideo from '../images/newVideos/web_dev_new.mp4';
// import myvideo from '../images/videos/web_dev.mp4'
import Inner_page2 from '../Inner_page2/Inner_page2';
import Layout3 from '../Inner_page3/Layout3';
import Logo_design from '../Inner_page3/Logo_design';
import Layout5 from '../Inner_page1/Layout5';
import Client_reviews from '../Landingpages/Client_reviews';
import News_and_events from '../Landingpages/News_and_events';
import FAQ from './FAQ';
import Contact_us_layout from '../Landingpages/Contact_us_layout';
import Blogs from '../Landingpages/Blogs';
import Footer from '../Landingpages/Footer';
import Inner4_layout4 from '../Inner_page4/Inner4_layout4';
import Layout2 from '../Inner_page2/Layout2';
import Navbar from '../Landingpages/Navbar';
import Website_Layout3 from './Layout3';
import Layout1 from './Layout1';
import Layout4 from './Layout4';
import { Helmet } from 'react-helmet';
import Backtotop from '../Backtotop';
import Scrolltotop from '../Scrolltotop';

const Web_development = () => {
  return (
    <div>
      <Helmet>
        <title>Responsive and SEO-Friendly Website Development Services</title>
        <meta
          name="description"
          content="Build your online presence with our expert website development service with dynamic, responsive and SEO friendly websites to drive traffic & your grow business."
        />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        {/* <link rel="canonical" href={`https://www.meridatechminds.com`} /> */}
      </Helmet>

      <div>
        <h1 className="d-none">website development services</h1>
        <h2 className="d-none">website development company</h2>

        <div className="video-background">
          <Navbar />
          <video
            autoPlay
            muted
            loop
            className="background-video"
          >
            <source
              src={myvideo}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <Layout1 />
        <Website_Layout3 />
        <Layout2 />
        <Layout4 />
        <Layout5 />
        <Client_reviews />
        <News_and_events />
        <FAQ />
        <Contact_us_layout />
        <Blogs />
        <Backtotop />
        <Scrolltotop />
      </div>
    </div>
  );
};

export default Web_development;
