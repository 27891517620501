import React from 'react';
import './CSS/main.css';
import './CSS/media-css.css';
import './CSS/animations.css';
import './CSS/main-text-css.css';
import './CSS/inner-page1.css';
import './CSS/inner-page2.css';
import './CSS/inner-page3.css';
import './CSS/blogs.css';
import './CSS/mobile_app.css';
import './CSS/software_consulting.css';
import './CSS/brand_building.css';
import './CSS/products.css';
import Homepage from './Landingpages/Homepage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Dummy from './Landingpages/Dummy';
import Inner1_Mainpage from './Inner_page1/Inner1_Mainpage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Inner_page2 from './Inner_page2/Inner_page2';
import Inner_page3 from './Inner_page3/Inner_page3';
import Inner_page4 from './Inner_page4/Inner_page4';
import Web_development from './Web_development/Web_development';
import Contact_us from './Landingpages/Contact_us';
import Services from './Services';
import All_blogs from './Blogs/All_blogs';
import View_blogs from './Blogs/View_blogs';
import CRM from './Products_pages/CRM_product/CRM';
import Task_Manager from './Products_pages/Task_manager/Task_Manager';
import Payroll_management from './Products_pages/Payroll_management/Payroll_management';
import Printlele from './Products_pages/Printlele/Printlele';
import Ticket_management_system from './Products_pages/Ticket_management_system/Ticket_management_system';
import Ocr from './Products_pages/OCR/Ocr';
import Algo_trading from './Products_pages/Algo_trading/Algo_trading';
import Background_verification from './Products_pages/Background_verification/Background_verification';
import Online_test from './Products_pages/Online_test/Online_test';
import Medical_billing from './Products_pages/Medical_billing/Madical_billing';
import HRMS from './Products_pages/HRMS/HRMS';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Payment from './Payment';
import 'bootstrap/dist/css/bootstrap.min.css';



const App = () => {
  return (
    <div>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route
            path="/"
            element={<Homepage />}
          />
          <Route
            path="/service/*"
            element={<Services />}
          />
          <Route
            path="/contact-us"
            element={<Contact_us />}
          />
          <Route
            path="/blogs"
            element={<All_blogs />}
          />
          <Route
            path="/blog/:slug"
            element={<View_blogs />}
          />
          <Route
            path="/service/software-consulting/crm-software-solutions"
            element={<CRM />}
          />
          <Route
            path="/service/software-consulting/task-manager-software"
            element={<Task_Manager />}
          />
          <Route
            path="/service/software-consulting/payroll-management-solutions"
            element={<Payroll_management />}
          />
          <Route
            path="/service/software-consulting/printlele-printing-solutions"
            element={<Printlele />}
          />
           <Route path="/payment" element={<Payment />}></Route>
          <Route
            path="/service/software-consulting/ticket-management-software-solutions"
            element={<Ticket_management_system />}
          />
          <Route
            path="/service/software-consulting/lernen-hub-learning-management-software"
            element={<Ocr />}
          />
          <Route
            path="/service/software-consulting/algo-trading-software-solutions"
            element={<Algo_trading />}
          />
          <Route
            path="/service/software-consulting/background-verification-services"
            element={<Background_verification />}
          />
          <Route
            path="/service/software-consulting/online-testing-software"
            element={<Online_test />}
          />
          <Route
            path="/service/software-consulting/best-medical-billing-software"
            element={<Medical_billing />}
          />
          <Route
            path="/service/software-consulting/human-resource-management-software"
            element={<HRMS />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
